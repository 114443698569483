import {useSettings} from '@wix/tpa-settings/react'
import React from 'react'
import settingsParams from '../../../../settingsParams'
import {FeaturedMobileCard} from './featured-mobile-card'
import {MobileCardsList} from './mobile-cards-list'

export const CardsMobile = () => {
  const {get} = useSettings()
  const featuredWidget = get(settingsParams.featuredEvent)

  return featuredWidget ? <FeaturedMobileCard /> : <MobileCardsList />
}
