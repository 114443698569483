import {useStyles} from '@wix/tpa-settings/react'
import {ExperimentNames, MOBILE_WIDGET_LAYOUT} from '@wix/wix-events-commons-statics'
import {useExperiments} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {Animated} from '../../../../../../../commons/components/animated'
import {isMobile, isSite} from '../../../../../../../commons/selectors/environment'
import stylesParams from '../../../../../stylesParams'
import {useWidgetState} from '../../../../hooks/state-provider'
import {useVisibilityStyles} from '../../../../hooks/use-visibility-styles'
import {getRibbonData} from '../../../../selectors/events'
import {isItemOpened, shouldShowCountdown} from '../../../../selectors/layout'
import {getComponentConfig, isListLayout, isMembersVisible} from '../../../../selectors/settings'
import classesV2 from '../../../classes-v2.scss'
import {Countdown} from '../../../countdown'
import {AdditionalInfoFullDateLocation} from '../../../full-date-location'
import {Ribbon} from '../../../ribbon'
import {RsvpButton} from '../../../rsvp-button'
import {Image} from '../../common/image'
import {AdditionalInfoToggle} from './additional-info-toggle'
import s from './cards-mobile-item.scss'
import {Date} from './date'
import {Description} from './description'
import {Members} from './members'
import {Share} from './share'
import {Title} from './title'

export const CardsMobileItem = ({event, hovered}: {event: ExtendedEvent; hovered: boolean}) => {
  const {
    allBreakpoints: {isListImageEnabled, isListCountdownEnabled},
  } = useVisibilityStyles()
  const {get} = useStyles()
  const {experiments} = useExperiments()

  const opened = useWidgetState(state => isItemOpened(state, event.id))
  const mobile = useWidgetState(isMobile)
  const showMembers = useWidgetState(state => isMembersVisible(state, event))
  const listLayout = useWidgetState(state => isListLayout(getComponentConfig(state)))
  const showRibbon = useWidgetState(state => getRibbonData(state, event).visible)
  const animates = useWidgetState(isSite)
  const showCountdown = shouldShowCountdown(event)
  const mobileGridLayout = get(stylesParams.mobileWidgetLayout) === MOBILE_WIDGET_LAYOUT.GRID
  const widgetSpacingUpdatesEnabled = experiments.enabled(ExperimentNames.WidgetSpacingUpdates)
  const listCardsLayoutTypeEnabled = experiments.enabled(ExperimentNames.ListCardsLayoutType)

  return (
    <div className={widgetSpacingUpdatesEnabled ? s.updatedSpacings : null}>
      {isListImageEnabled() ? (
        <div
          className={classNames(s.image, {
            [s.padding]: !widgetSpacingUpdatesEnabled || (listCardsLayoutTypeEnabled ? !mobileGridLayout : listLayout),
          })}
        >
          <Image event={event} />
        </div>
      ) : null}
      <div
        className={
          widgetSpacingUpdatesEnabled && (listCardsLayoutTypeEnabled ? mobileGridLayout : !listLayout)
            ? s.eventInfoPadding
            : null
        }
      >
        <div className={widgetSpacingUpdatesEnabled ? s.eventInfo : null}>
          {showRibbon ? (
            <Ribbon event={event} className={classNames(s.ribbon, {[s.overrideMargin]: !showCountdown})} />
          ) : null}
          {isListCountdownEnabled() && showCountdown && (
            <div className={s.countdown}>
              <Countdown event={event} />
            </div>
          )}
          {widgetSpacingUpdatesEnabled && <Title event={event} />}
          <Date event={event} />
          {!widgetSpacingUpdatesEnabled && <Title event={event} />}
          <AdditionalInfoToggle opened={opened} active={!mobile && (hovered || opened)} />
          <div className={s.additionalInfoContainer}>
            <Animated disabled={!animates} show={opened} height={calculateContentHeight}>
              <div className={classNames(s.additionalInfo, listLayout ? s.listContentText : s.mobileCardsContentText)}>
                <AdditionalInfoFullDateLocation
                  event={event}
                  dateClassName={classesV2.hoverEventDate}
                  locationClassName={classesV2.hoverEventLocation}
                />
                {widgetSpacingUpdatesEnabled && <Description event={event} />}
                {showMembers ? <Members event={event} /> : null}
                {!widgetSpacingUpdatesEnabled && <Description event={event} />}
                <Share event={event} />
              </div>
            </Animated>
          </div>
          <RsvpButton event={event} fullWidth={mobile} containerClassName={s.buttonContainer} />
        </div>
      </div>
    </div>
  )
}

const calculateContentHeight = (element: HTMLElement) => element.offsetHeight + 60
